import { usePathname } from "next/navigation";
import { useEffect, useRef } from "react";

export const GA_ID = process.env.NEXT_PUBLIC_GA_ID as string;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
  const contentGroup = analyticsGrouping(url.pathname);

  (window as any).gtag("config", GA_ID as string, {
    page_path: url.pathname,
    content_group: contentGroup,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action, { event_category, event_label, value }) => {
  (window as any).gtag("event", action, {
    event_category,
    event_label,
    value,
  });
};

export const useGtag = () => {
  const pathname = usePathname(); // Get current route

  // Save pathname on component mount into a REF
  const savedPathNameRef = useRef(pathname);

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      pageview(url);
    };

    if (savedPathNameRef.current !== pathname) {
      handleRouteChange(new URL(pathname, window.location.origin));
      savedPathNameRef.current = pathname;
    }

    document.querySelectorAll("[data-ga-event]").forEach((element) => {
      const htmlElement = element as HTMLElement;

      htmlElement.addEventListener("click", () => {
        const { category, action, label, value } = JSON.parse(
          htmlElement.dataset.gaEvent,
        );

        window.gtag("event", action, {
          event_category: category,
          event_label: label ?? window.location.pathname,
          event_value: value ?? "0",
        });
      });
    });
  }, [pathname]);
};

// this code is also used in src/app/[locale]/components/Analytics.tsx
// hardcoded in the script tag string literal
function analyticsGrouping(path: string) {
  if (path.includes("/kitas/") || path.endsWith("/kitas")) return "kitas";
  if (path.includes("/events/") || path.endsWith("/events")) return "events";
  if (path.includes("/blog/") || path.endsWith("/blog")) return "blog";
  if (path.includes("/timeline/") || path.endsWith("/timeline"))
    return "timeline";
  if (path.includes("/perks/") || path.endsWith("/perks")) return "perks";
  if (path.includes("/guides/") || path.endsWith("/guides")) return "guides";
  return "other";
}
