"use client";
import { useAppSelector } from "@/store/hooks";
import { Modal } from "../Modal";
import { useLocale } from "next-intl";
import { usePathname } from "@/navigation";

export function NewsletterModal({ handleClose }: { handleClose?: () => void }) {
  const selectedCategory = useAppSelector((state: any) => state.category);
  const locale = useLocale();
  const pathname = usePathname();
  let modalForm;

  if (selectedCategory === "Kita Search" || pathname.includes("kita-search")) {
    if (locale === "de") {
      modalForm =
        "https://b5cb9995.sibforms.com/serve/MUIFAFtwjBQg6FMu7Ny_P3BnG5cD8tL3m8Y17-Hew7eDAzMfhyV3etomFABl_WuWDKJW-JKjLgaB_V9siKvxquhIxOU-wZyQNxjcN-TH-y_QJldptPTqCrfFWWIquF5seM_kontZ2ynLEuGgaVaq4AwZedOiLEiMj7x4mJRuvPXukMoqKDV2WbKa0NSOwdTF1Gsu4rblDJM1HXWR";
    } else {
      modalForm =
        "https://b5cb9995.sibforms.com/serve/MUIFAF2EOaYS3AJZd5E373dYzim24kMQl_Zps_Z-VT_kbVI1KeBWShfhizZkw7ymx-Ztz1IJleKRmNAaumyf3nByc0CEyXWHQcFUzh0hPO7yNBmpvrCXthOPVP3JKsVUW6Z17AXimvE91MNCn4f2Ibv96iKoNI1LpfwLi2uWXHdcvdWVy2UUVrG5dJjt7pTG2hjyvu2jEFx9jK8S";
    }
  } else if (
    selectedCategory === "Before Your Baby is Born" ||
    pathname.includes("expecting")
  ) {
    modalForm =
      "https://b5cb9995.sibforms.com/serve/MUIFAFKIDF14vTS9O8nMy5RnDJJB7AGUi1FjDeMkjsGXQZfKKWCk4QWY0PUQ5hqdn_3PPozjdm3OlgL7KVR4whueqLcKcWgwW3_Htu8kYyIZMb6-XXM5qWwdP7ffFXINJkOcGN9JA8_V7p8T5Sj-z4LlKw5FsMftYWXnGzG9BDMN5bmzi-plxIz4VVj0j1MqhpVGygkIE9hOTpK1";
  } else if (pathname.includes("events")) {
    modalForm =
      "https://b5cb9995.sibforms.com/serve/MUIFAFmXdLtzIfMA9CsmZRSJZ12SndMl5ATresyYSTuvfQylb4tXBsRK00Sas83GQe_U3pKDEAOGa2WjXJ7zZ0jk4L8aCfG5XF4ewYWel57IIJZJapkZInktKtGgRnjpGcvDt6fFnb6Gc1Z5gaYeFXZ7DxyAmwM3Wxdo2G93u_7TGODRfV-7Uza3N__RSk_Mq_rs4UfU4dSt8mYS";
  } else {
    modalForm =
      "https://b5cb9995.sibforms.com/serve/MUIFAMMp5NPdjsB5gnRoGkzl9G5eNtFfj4EbcrlgkO9qTTBwZw4Ig_B9vwMsP_hOMM7NO2kAAo9ITU9KsjAClMuvjUGCfgjPE-91eVDXFGBy5uh_7UiPh9tot5FH4HKPKRLwy9nQW5TEURRLmkjxZYGrwcO2It26PZsKOc4WOF6QYsb2DxLjp0rIsgc_PjPUn4scCHB2aUsDkcvH";
  }

  return (
    <Modal withCloseButton handleClose={handleClose} modalBoxClasses="p-0">
      <div className="h-[600px]">
        <iframe
          width="100%"
          height="100%"
          src={modalForm}
          allowFullScreen
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "100%",
          }}
        />
      </div>
    </Modal>
  );
}
