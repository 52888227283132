"use client";

import { i18n } from "@/i18n";
import { useLocale } from "next-intl";
import { usePathname, useRouter } from "@/navigation";
import { useTransition } from "react";

export function LocaleSwitcher() {
  const [_, startTransition] = useTransition();
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();

  function handleSelection(value: string) {
    startTransition(() => {
      router.replace(`${pathname}`, { locale: value });
    });
  }

  return (
    <div className="dropdown dropdown-end dropdown-top">
      <div tabIndex={0} role="button" className="btn m-1 bg-neutral">
        {locale.toUpperCase()}
      </div>
      <ul
        tabIndex={0}
        className="menu dropdown-content z-[1] w-24 bg-neutral p-2 text-base-300"
      >
        {i18n.locales.map((cur) => (
          <li key={cur} onClick={() => handleSelection(cur)}>
            <a className="hover:bg-base-200">{cur.toUpperCase()}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}
